<template>
  <div id="tabMain" class="container root">
    <div class="row">
      <div class="col p-0">
        <nav class="nav nav-tabs nav-fill">
          <a
            v-for="(item, index) in tabs"
            :key="index"
            :id="item.route"
            :class="'nav-item nav-link ' + active(item.route)"
            v-on:click="setActive(item.route)"
            >{{ item.name }}</a
          >
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { isPassedConditions } from '@/helper/uiConfig'

export default {
  name: 'tabMain',
  computed: {
    saleType() {
      const { user_data } = this.$store.state
      const customerSaleType = user_data?.customerSaleType
      return customerSaleType ? customerSaleType.toLowerCase() : ''
    },
    tabs() {
      const pathName = this.$route.name
      const { saleType } = this
      let tabList = [
        {
          name: 'คำนวณ',
          route: 'mainCalculator',
        },
        {
          name: 'คะแนน',
          route: 'invoices',
        },
        {
          name: 'แลกรางวัล',
          route: 'rewards',
        },
        {
          name: 'ลุ้นรางวัล',
          route: 'lucky-draws',
        },
        {
          name: 'แชะ&โชว์',
          route: 'ai-shelf',
        },
        // {
        //   name: "ลุ้นรางวัล",
        //   route: "lucky-draws-campaign",
        // },
        {
          name: 'ประวัติ',
          route: 'history',
        },
        {
          name: 'เงื่อนไข',
          route: 'condition',
        },
      ]

      switch (pathName) {
        // case "calculator":
        case 'all-coupon':
        case 'my-coupon':
        case 'use-coupon': {
          tabList = [
            // {
            //   name: "คำนวณ",
            //   route: "calculator",
            // },
            {
              name: 'คูปอง',
              route: 'all-coupon',
            },
            {
              name: 'คูปองของฉัน',
              route: 'my-coupon',
            },
          ]
          return this.filterTabConfig(tabList)
        }
        default: {
          if (saleType !== '') {
            switch (saleType.toLowerCase()) {
              case 'wholesale': {
                // const index = tabList.findIndex((i) => i.route === "condition");
                // tabList[index].name = "สรุปผล";
                return this.filterTabConfig(tabList)
              }
              default:
                return this.filterTabConfig(tabList)
            }
          } else {
            return this.filterTabConfig(tabList)
          }
        }
      }
    },
  },
  methods: {
    filterTabConfig(list) {
      const { user_data } = this.$store.state
      return list.filter(
        (e) =>
          // check hide config
          isPassedConditions({
            configName: `tab_${e.route}_hide`,
            configData: user_data.ui_configs,
            targetData: user_data,
          }) != '1'
      )
    },
    active(r) {
      const { name } = this.$route
      if (
        r === name ||
        (name === 'use-coupon' && r === 'my-coupon') ||
        (name === 'ai-shelf-history' && r === 'ai-shelf') ||
        (name === 'ai-shelf-upload' && r === 'ai-shelf')
      ) {
        return 'active'
      }
      return ''
    },
    setActive(routeName) {
      const { name } = this.$route
      window.scrollTo(0, 0)
      if (name !== routeName) {
        let elmActive = document.getElementById(name)
        if (
          name !== 'use-coupon' ||
          name !== 'ai-shelf-history' ||
          name !== 'ai-shelf-upload'
        ) {
          try {
            elmActive?.classList.remove('active')
          } catch (err) {
            console.log(err)
          }
        }
        const elm = document.getElementById(routeName)
        elm?.classList.add('active')
        this.$router.push({ name: routeName })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
$light-blue: #f0f6ff;

nav {
  background: $light-blue;
  border: 1px solid transparent;
  font-size: 14px;
  .nav-link {
    align-items: center;
    border-bottom: 5px solid $light-blue;
    display: flex;
    justify-content: center;
    height: 50px;
    padding: 0.2rem 0 !important;
    &.active {
      background: $light-blue;
      font-weight: 700;
    }
  }
}
</style>
