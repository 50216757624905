<template>
  <div id="app_root">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
// import Swal from "sweetalert2";

const defaultLayout = "defaultLayout";

export default {
  computed: {
    layout() {
      return this.$route.meta.layout || defaultLayout;
    },
    // routeLiff() {
    //   console.log();
    //   return this.$route.meta.liff;
    // }
  },
  watch: {
    // routeLiff(val) {
    //   console.log("found routeliff:", val);
    //   if (val && !this.$store.state.user_line.userId) {
    //     this.init();
    //   }
    // }
  },
  methods: {
    // async init() {
    //   console.log("route:", this.$route);
    //   console.log("main mount route:", defaultLiff);
    //   // get user line
    //   const userLine = await this.$Liff.getLiffProfile(defaultLiff);
    //   console.log("userline :", userLine);
    //   // check if user
    //   if (!userLine) {
    //     Swal.fire("เกิดข้อผิดพลาด", "กรุณาติดต่อเจ้าหน้าที่", "error").then(
    //       () => {
    //         window.liff.closeWindow();
    //       }
    //     );
    //   }
    //   // fake delay for dev
    //   setTimeout(() => {
    //     this.$store.commit("SET_USER_LINE", userLine);
    //   }, 5000);
    // }
  },
  mounted() {
    // window.addEventListener("focusin", function(e) {
    // console.log(e);
    // if (e.target.tagName == "INPUT") {
    //     document.body.style.height = document.body.offsetHeight + 100 + "px";
    //     if (e.target.id) {
    //       document.getElementById(e.target.id).scrollIntoView();
    //       window.scrollBy(0, -50);
    //     }
    // }
    // });

    window.addEventListener("focusout", (e) => {
      if (e.target.tagName == "INPUT") {
        window.scrollBy(0, 1);
        // document.body.style.height = "100%";
      }
    });
  },
};
</script>
<style lang="scss">
@import "~@/assets/fonts/fonticon/fonticon.css";

#app_root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  // color: #6c6c6c;
  letter-spacing: 0.4px;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<style>
html,
body,
#app_root {
  height: 100%;
}

body {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
  width: 0;
  height: 0;
}
</style>
