// show error and close liff window when init error
<template>
  <div id="SSClick">
    <div id="fixTop">
      ลุ้นดื่ม เอส ฟรี!
    </div>
    <div
      id="main_content"
      class="main_content px-3"
      :style="{ paddingTop: fixTop + 'px' }"
    >
      <slot />
    </div>
  </div>
</template>
<script>

export default {
  async mounted() {
    // find and set padding main_content
    // document.getElementById("main_content").style.paddingTop =
    //   document.getElementById("fixTop").clientHeight + "px";

    document.title = "Sermsuk Click"
  },
  computed: {
    fixTop() {
      // const { customerSaleType } = this.$store?.state?.user_data;
      let fixHeight = 70;
      // switch (customerSaleType?.toLowerCase()) {
      //   case "exclusivity":
      //   case "wholesale": {
      //     fixHeight = 190;
      //     break;
      //   }
      //   default:
      //     break;
      // }
      return fixHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
#SSClick {
  max-width: 450px;
  margin: 0 auto;
  
}

#fixTop {
  z-index: 2;
  position: fixed;
  width: 100%;
  max-width: inherit;
  text-align:center;
  padding:8px;
  font-size:20px;
  font-weight:bold;
  background-color: #001b8f;
  color: white;
  border-bottom: 1px solid #001b8fbd;
}
.main_content {
  /* padding-top: 135px; */
  overflow: auto;
  // background-image: url("~@/assets/bg_ssclick_lobster.png");
  background-size: 100% ;
  background-repeat: no-repeat; 
  background-color: #000f80;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
}
</style>
