export function isPassedConditions({ configName, configData, targetData }) {
  try {
    if (configData) {
      const config = configData.find((c) => c.name === configName);
      if (config?.conditions) {
        let totalChecksPassed = 0;
        const totalConditions = Object.keys(config.conditions).length;
        for (const [cKey, cValue] of Object.entries(config.conditions)) {
          if (targetData[cKey] && cValue.includes(targetData[cKey])) {
            totalChecksPassed++;
          }
        }
        if (totalChecksPassed >= totalConditions) {
          return config.value;
        }
      }
    }
    return null;
  } catch (err) {
    console.log("Error:", err);
    return null;
  }
}
