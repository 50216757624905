// show error and close liff window when init error
<template>
  <div id="Main">
    <div id="fixTop">
      <TopHead />
      <TabMain />
    </div>
    <div
      id="main_content"
      class="main_content"
      :style="{ paddingTop: fixTop + 'px' }"
    >
      <slot />
    </div>
  </div>
</template>
<script>
import TopHead from "@/components/topHead.vue";
import TabMain from "@/components/tabMain.vue";

export default {
  components: { TopHead, TabMain },
  async mounted() {
    // find and set padding main_content
    // document.getElementById("main_content").style.paddingTop =
    //   document.getElementById("fixTop").clientHeight + "px";
  },
  computed: {
    fixTop() {
      // const { customerSaleType } = this.$store?.state?.user_data;
      let fixHeight = 125;
      // switch (customerSaleType?.toLowerCase()) {
      //   case "exclusivity":
      //   case "wholesale": {
      //     fixHeight = 190;
      //     break;
      //   }
      //   default:
      //     break;
      // }
      return fixHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
#Main {
  max-width: 450px;
  margin: 0 auto;
  height: 100%;
}

#fixTop {
  z-index: 2;
  position: fixed;
  width: 100%;
  max-width: inherit;
}
.main_content {
  /* padding-top: 135px; */
  overflow: auto;
  height: 100%;
}
</style>
