// show error and close liff window when init error
<template>
  <div id="Main">
    <div id="fixTop">
      <TopHead />
    </div>
    <div id="main_content" class="main_content">
      <slot />
    </div>
  </div>
</template>
<script>
import TopHead from "@/components/topHead.vue";

export default {
  components: { TopHead },

  async mounted() {
    // find and set padding main_content
    document.getElementById("main_content").style.paddingTop =
      document.getElementById("fixTop").clientHeight + "px";
  }
};
</script>

<style lang="scss" scoped>
#Main {
  max-width: 450px;
  margin: 0 auto;
}

#fixTop {
  z-index: 2;
  position: fixed;
  width: 100%;
  max-width: inherit;
}

.main_content {
  /* padding-top: 135px; */
  overflow: auto;
  position: relative;
}
</style>
