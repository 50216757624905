<template>
  <div id="campaign-layout">
    <div class="header" v-if="checkPage">
      <img
        @click="_back"
        class="ml-3"
        :src="require('@/assets/icon/back-icon.svg')"
        width="15px"
        height="25px"
      />
      <div class="text-center">
        {{ title }}
      </div>
    </div>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
import { findIndex, includes } from "lodash";

export default {
  computed: {
    checkPage() {
      const { name } = this.$route;
      return name !== "lucky-draws-campaign" ? true : false;
    },
    title() {
      const { name } = this.$route;
      const { header } = this;
      const index = findIndex(header, (i) => includes(i.pathName, name));
      return header[index].name;
    },
  },
  data: () => ({
    header: [
      { name: "รายละเอียดกิจกรรม", pathName: "lucky-draws-campaign-detail" },
      {
        name: "ตรวจสิทธิ์การลุ้นรางวัล",
        pathName: "check-lucky-draws-campaign",
      },
      { name: "ประกาศผลรางวัล", pathName: "announce-lucky-draws-campaign" },
      { name: "ประกาศผลรางวัล", pathName: "announce-lucky-draws-campaign-detail" },
    ],
  }),
  methods: {
    _back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
$blue: #002fb1;

#campaign-layout {
  max-width: 450px;
  margin: 0 auto;
  .header {
    align-items: center;
    background: #002fb1;
    color: #fff;
    display: flex;
    height: 60px;
    img {
      cursor: pointer;
    }
    .text-center {
      width: 100%;
      margin-right: 20px;
    }
  }
}
</style>