<template>
  <div class="nav-tab-container pt-4 px-3 pb-2">
    <nav class="nav nav-tabs nav-fill">
      <a
        v-for="(item, index) in tabs"
        :key="index"
        :id="item.routeName"
        :class="'nav-item nav-link ' + activeNav(item.route)"
        @click="setActiveNav(item.route)"
        >{{ item.name }}</a
      >
    </nav>
  </div>
</template>

<script>
export default {
  data: () => ({
    tabs: [
      {
        name: "คูปองที่ใช้ได้",
        route: "my-coupon",
        routeName: 'sub-my-coupon'
      },
      {
        name: "คูปองที่ใช้ไม่ได้",
        route: "use-coupon",
        routeName: 'sub-use-coupon'
      },
    ],
  }),
  methods: {
    activeNav(r) {
      const { name } = this.$route;
      return r == name ? "active" : "";
    },
    setActiveNav(routeName) {
      const { name } = this.$route;
      window.scrollTo(0, 0);
      if (name !== routeName) {
        let elmActive = document.getElementById(`sub-${name}`);
        try {
          elmActive.classList.remove("active");
        } catch (err) {
          console.log(err);
        }
        const elm = document.getElementById(`sub-${routeName}`);
        elm.classList.add("active");
        this.$router.push(routeName);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
$black: #000;
$blue: #189ad6;
$light-gray: #ced3da;
$white: #fff;
$radius: 6px;

.nav-tab-container {
  nav {
    border: 1px solid $light-gray;
    border-radius: $radius;
    font-size: 14px;
    .nav-link {
      align-items: center;
      color: $black;
      display: flex;
      justify-content: center;
      &.active {
        background: $blue;
        border-radius: $radius;
        border-bottom: none;
        color: $white;
      }
    }
  }
}
</style>
