import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const state = {
  user_line: {},
  storeId: "",
  rewards: [],
  activeCategoryID: 0,
  rewardsStatus: "loading",
  user_data: {
    point: 0,
    pending_point: 0,
  },
  redeems: [],
  redeemStatus: "loading",
  wholesaleTarget: null,
  catalogList: [],
  registerData: {
    citizenID: null,
    shopCode: null,
  },
  otp: {
    otpRef: null,
    phoneNumber: null,
  },
};

export const mutations = {
  SET_CATALOG(state, value) {
    state.catalogList = value;
  },
  SET_USER_LINE(state, value) {
    state.user_line = value;
  },
  SET_STORE_ID(state, value) {
    state.storeId = value;
  },
  SET_REWARDS(state, value) {
    state.rewards = value;
    state.rewardsStatus = "ok";
  },
  SET_REDEEMS(state, value) {
    state.redeems = value;
    state.redeemStatus = "ok";
  },
  SET_ACTIVE_CAT_ID(state, value) {
    state.activeCategoryID = value.id;
  },
  SET_REWARDS_STATUS(state, value) {
    state.rewardsStatus = value;
  },
  SET_REDEEMS_STATUS(state, value) {
    state.redeemStatus = value;
  },
  SET_USER_DATA(state, value) {
    state.user_data = value;
  },
  SET_CURRENT_POINT(state, value) {
    // state.user_data.point = value
    state.user_data = {
      ...state.user_data,
      point: value,
    };
  },
  SET_WHOLESALE_TARGET(state, value) {
    state.wholesaleTarget = value;
  },
  SET_REGISTER_DATA(state, value) {
    state.registerData = { ...state.registerData, ...value };
  },
  SET_OTP_DATA(state, value) {
    state.otp = { ...state.otp, ...value };
  },
};

export const actions = {
  setCatalog(context, value) {
    context.commit("SET_CATALOG", value);
  },
  setStoreID(context, value) {
    context.commit("SET_STORE_ID", value);
  },
  setUserData(context, value) {
    context.commit("SET_USER_DATA", value);
  },
  setCurrentPoint(context, value) {
    context.commit("SET_CURRENT_POINT", value);
  },
  setWholesaleTarget(context, value) {
    context.commit("SET_WHOLESALE_TARGET", value);
  },
  setRegisterData(context, value) {
    context.commit("SET_REGISTER_DATA", value);
  },
  setOtpData(context, value) {
    context.commit("SET_OTP_DATA", value);
  },
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {},
});
