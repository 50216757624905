export default async function unlink(userId, jwt) {
    await fetch(
        `${process.env.VUE_APP_API_URL}/unlinkRichmenu/${userId}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${jwt}`,
            },
        }
    )
    return
}