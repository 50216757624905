// get message from body code response
// async function getMesssage(code) {
//     switch (code) {
//         case 12001:
//             return { user_message: "ข้อผิดพลาดการตรวจสอบข้อมูลเข้าใช้ กรุณาติดต่อเจ้าหน้าท่ี่ (12001)", icon: "warning" }
//         case 12002:
//             return { user_message: "ข้อผิดพลาดช่องทางเข้าใช้ กรุณาติดต่อเจ้าหน้าท่ี่ (12002)", icon: "warning" }
//         case 12003:
//             return { user_message: "กรุณาตรวจสอบการเพิ่มเพื่อน<br>และเข้าใช้งานอีกครั้ง หรือติดต่อจ้าหน้าที่", icon: "warning" }
//         default:
//             return { user_message: `ข้อผิดพลาดการเข้าใช้ กรุณาเข้าใช้งานอีกครั้ง (${code})`, icon: "warning" }
//     }
// }

export default async function getJWT(reqData) {
  // console.log('getJWT...')
  // console.log('by:', reqData)
  try {
    if (!reqData.channel) {
      throw 'no channel'
    }

    const res = await fetch(`${process.env[`VUE_APP_API_URL`]}/auth`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqData),
    })

    // console.log('getJWT status:', res.status)

    const body = await res.json()

    switch (res.status) {
      case 200: {
        if (!body?.access_token) {
          throw 'getJWT body not correct'
        }
        return body.access_token
      }
      default:
        throw 'not ok'
    }
  } catch (error) {
    console.error('getJWT:', error)
    return null
  }
}
