import Vue from "vue";
import VueRouter from "vue-router";
import Swal from "sweetalert2";
import authLiff from "@/helper/authLiff";
import getJWT from "@/helper/getJWT";
import StackdriverErrorReporter from "stackdriver-errors-js";
import jwtDecode from "jwt-decode";

import { GET_READONLY } from "@/helper/common";
import unlinkRichmenu from "@/helper/unlinkRichmenu";

const errorHandler = new StackdriverErrorReporter();
errorHandler.start({
  key: "AIzaSyCk9QyldCqUrewlnd6GfGCU8vS0XFA80tY",
  projectId: "sermsuk-family",
  // Other optional arguments can also be supplied, see below.
});

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '*/',
  //   name: 'home',
  //   meta: {
  //     auth: false,
  //     liff: '',
  //   },
  //   component: () => import('../views/Home.vue'),
  // },
  {
    path: "/user/scan-qr",
    name: "scanQr",
    meta: {
      liff: "",
    },
    component: () => import("../views/user/scanQr.vue"),
  },
  {
    path: "/user/enter-shop",
    name: "enterShop",
    meta: {
      liff: "",
      requireUser: false,
    },
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/user/enterShop.vue"),
  },
  {
    path: "/user/processRegister",
    name: "processRegister",
    props: true,
    meta: {
      liff: "",
      requireUser: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../views/user/processRegister.vue"
      ),
  },
  {
    path: "/user/auth/:shopid/:existMember",
    props: true,
    name: "auth",
    meta: {
      liff: "",
      requireUser: false,
    },
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/user/auth.vue"),
  },
  {
    path: "/about",
    name: "about",
    meta: {
      liff: "",
    },
    component: () => import("../views/About.vue"),
  },
  {
    path: "/user/otp",
    name: "otp",
    props: true,
    meta: {
      liff: "",
      requireUser: false,
    },
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/user/otp.vue"),
  },
  {
    path: "/user/set-pin/:otp",
    name: "set-pin",
    props: true,
    meta: {
      liff: "",
      requireUser: false,
    },
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/user/setPin.vue"),
  },
  {
    path: "/user/success",
    name: "update-success",
    meta: {
      liff: "",
      requireUser: false,
    },
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/user/success.vue"),
  },
  {
    path: "/pdpa",
    name: "pdpa",
    meta: {
      liff: "",
    },
    component: () =>
      import(/* webpackChunkName: "pdpa" */ "../views/PdpaMenu.vue"),
  },
  {
    path: "/invoices",
    name: "invoices",
    meta: {
      layout: "mainLayout",
      liff: "",
    },
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/main/invoices.vue"),
  },
  {
    path: "/rewards",
    name: "rewards",
    meta: {
      layout: "mainLayout",
      liff: "",
    },
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/main/rewards.vue"),
  },
  {
    path: "/lucky-draws",
    name: "lucky-draws",
    meta: {
      layout: "mainLayout",
      liff: "",
    },
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/main/redeems.vue"),
  },
  {
    path: "/lucky-draws/announcement",
    name: "lucky-draws-announcement",
    props: true,
    meta: {
      liff: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "main" */ "../views/main/redeemsAnnouncement.vue"
      ),
  },
  {
    path: "/lucky-draws/:lucky_draws_id",
    name: "lucky-draws-detail",
    props: true,
    meta: {
      layout: "rewardLayout",
      liff: "",
    },
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/main/redeemDetail.vue"),
  },
  {
    path: "/confirm-point",
    name: "confirmPoint",
    props: true,
    meta: {
      liff: "",
    },
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/main/confirmPoint.vue"),
  },
  {
    path: "/reward/:reward_id",
    name: "rewardDetail",
    props: true,
    meta: {
      layout: "rewardLayout",
      liff: "",
    },
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/main/rewardDetail.vue"),
  },
  {
    path: "/history",
    name: "history",
    props: true,
    meta: {
      layout: "mainLayout",
      liff: "",
    },
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/main/history.vue"),
  },
  {
    path: "/condition",
    name: "condition",
    props: true,
    meta: {
      layout: "mainLayout",
      liff: "",
    },
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/main/condition.vue"),
  },
  {
    path: "/point-rule",
    name: "point-rule",
    props: true,
    meta: {
      liff: "",
    },
    component: () =>
      import(/* webpackChunkName: "standalone" */ "../views/PointRule.vue"),
  },
  {
    path: "/promotions",
    name: "promotions",
    props: true,
    meta: {
      liff: "",
    },
    component: () =>
      import(/* webpackChunkName: "standalone" */ "../views/Promotions.vue"),
  },
  {
    path: "/comingsoon",
    name: "comingsoon",
    props: true,
    meta: {
      liff: "",
    },
    component: () =>
      import(/* webpackChunkName: "standalone" */ "../views/ComingSoon.vue"),
  },
  {
    path: "/test",
    name: "test",
    props: true,
    meta: {
      liff: "",
    },
    component: () =>
      import(/* webpackChunkName: "main" */ "../components/addAddress.vue"),
  },
  {
    path: "/pin-login",
    name: "pin-login",
    meta: {
      auth: false,
      liff: "",
    },
    component: () =>
      import(/* webpackChunkName: "pin-login" */ "../views/user/pin-login.vue"),
  },
  {
    path: "/menu",
    name: "menu",
    meta: {
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: "menu" */ "../views/main/menu.vue"),
  },
  {
    path: "/mainCalculator",
    name: "mainCalculator",
    meta: {
      layout: "mainLayout",
      liff: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "calculator" */ "../views/main/calculator.vue"
      ),
  },
  {
    path: "/calculator",
    name: "calculator",
    meta: {
      layout: "mainLayout",
      liff: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "calculator" */ "../views/main/calculator.vue"
      ),
  },
  {
    path: "/all-coupon",
    name: "all-coupon",
    meta: {
      layout: "mainLayout",
      liff: "",
    },
    component: () =>
      import(/* webpackChunkName: "coupon" */ "../views/main/coupon.vue"),
  },
  {
    path: "/my-coupon",
    name: "my-coupon",
    meta: {
      layout: "couponLayout",
      liff: "",
    },
    component: () =>
      import(/* webpackChunkName: "coupon" */ "../views/main/coupon.vue"),
  },
  {
    path: "/use-coupon",
    name: "use-coupon",
    meta: {
      layout: "couponLayout",
      liff: "",
    },
    component: () =>
      import(/* webpackChunkName: "coupon" */ "../views/main/coupon.vue"),
  },
  {
    path: "/lucky-draws-campaign",
    name: "lucky-draws-campaign",
    meta: {
      layout: "mainLayout",
      liff: "",
    },
    component: () =>
      import(/* webpackChunkName: "campaign" */ "../views/main/campaign.vue"),
  },
  {
    path: "/check-lucky-draws-campaign",
    name: "check-lucky-draws-campaign",
    meta: {
      layout: "campaignLayout",
      liff: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "checkCampaign" */ "../views/main/checkCampaign.vue"
      ),
  },
  {
    path: "/announce-lucky-draws-campaign",
    name: "announce-lucky-draws-campaign",
    meta: {
      layout: "campaignLayout",
      liff: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "announceCampaign" */ "../views/main/announceCampaign.vue"
      ),
  },
  {
    path: "/announce-lucky-draws-campaign-detail",
    name: "announce-lucky-draws-campaign-detail",
    props: true,
    meta: {
      layout: "campaignLayout",
      liff: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "announceCampaignDetail" */ "../views/main/announceCampaignDetail.vue"
      ),
  },
  {
    path: "/ai-shelf",
    // layout: "mainLayout",
    component: () =>
      import(/* webpackChunkName: "aiShelfDetection" */ "../views/main/AIShelfDetection/layout.vue"),
    children: [
      {
        path: "",
        name: "ai-shelf",
        props: true,
        meta: {
          layout: "mainLayout",
        },
        component: () =>
          import(/* webpackChunkName: "aiShelfDetection" */ "../views/main/AIShelfDetection/index.vue"),
      },
      {
        path: "upload",
        name: "ai-shelf-upload",
        props: true,
        meta: {
          layout: "mainLayout",
        },
        component: () =>
          import(/* webpackChunkName: "aiShelfDetectionUpload" */ "../views/main/AIShelfDetection/upload.vue"),
      },
      {
        path: "history",
        name: "ai-shelf-history",
        props: true,
        meta: {
          layout: "mainLayout",
        },
        component: () =>
          import(/* webpackChunkName: "aiShelfDetectionHistory" */ "../views/main/AIShelfDetection/history.vue"),
      },
    ]
  },
  {
    path: "/ssclick/:shopcode/register",
    name: "ssclick-register",
    props: true,
    meta: {
      layout: "ssclickLayout",
      liff: "",
      auth: false,
      auth_line: true,
      requireUser: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "ssclickRegister" */ "../views/ssclick/Register.vue"
      ),
  },
  {
    path: "/ssclick/:shopcode/register-success",
    name: "ssclick-register-success",
    props: true,
    meta: {
      layout: "ssclickLayout",
      liff: "",
      auth: false,
      requireUser: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "ssclickRegisterSuccess" */ "../views/ssclick/RegisterSuccess.vue"
      ),
  },
  {
    path: "/ssclick/:shopcode/campaign",
    name: "ssclick-campaign",
    props: true,
    meta: {
      layout: "ssclickLayout",
      liff: "",
      auth: false,
      requireUser: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "ssclickRegisterSuccess" */ "../views/ssclick/Campaign.vue"
      ),
  },
  {
    path: "/ssclick/congrats/:refcode",
    name: "ssclick-congrats",
    props: true,
    meta: {
      layout: "ssclickLayout",
      liff: "",
      auth: false,
      requireUser: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "ssclickRegisterSuccess" */ "../views/ssclick/Congrats.vue"
      ),
  },
  {
    path: "/ssclick/sorry",
    name: "ssclick-sorry",
    props: true,
    meta: {
      layout: "ssclickLayout",
      liff: "",
      auth: false,
      // requireUser: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "ssclickRegisterSuccess" */ "../views/ssclick/Sorry.vue"
      ),
  },
  {
    path: "/ssclick/expired",
    name: "ssclick-expired",
    props: true,
    meta: {
      layout: "ssclickLayout",
      liff: "",
      auth: false,
      // requireUser: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "ssclickRegisterSuccess" */ "../views/ssclick/CampaignExpire.vue"
      ),
  },
  {
    path: "/:catchAll(.*)",
    name: "notfound",
    meta: {
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: "notFound" */ "../views/ComingSoon.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

let userIdTrack = "";

// push each route
function pushTrack(route_name, fullPath) {
  try {
    window["_paq"].push(["setDocumentTitle", route_name]);
    window["_paq"].push(["setCustomUrl", fullPath]);
    window["_paq"].push(["trackPageView"]);
  } catch (error) {
    console.log(error);
  }
}

// set userId of tracking
function setTrack(user = "") {
  try {
    window["_paq"].push(["setUserId", user.toString()]);
    window.Sentry.setUser({ id: user });
    errorHandler.setUser(user);
  } catch (error) {
    console.log(error);
  }
}

// save JWT,data to prototype
async function saveToken(jwt) {
  const decodeJWT = jwtDecode(jwt);
  console.log("JWT", jwt);
  console.log("decode JWT: ", decodeJWT);
  Vue.prototype.$JWT = jwt;
  Vue.prototype.$userJWT = decodeJWT;
}

async function checkPermission(jwt, to) {
  const decodeJWT = jwtDecode(jwt);
  if (
    decodeJWT?.u &&
    decodeJWT?.u?.id === null &&
    to.meta.requireUser !== false
  ) {
    const { userId } = Vue.prototype.$userLine?.profile;
    await unlinkRichmenu(userId, jwt);
    throw { code: "cpm1" };
  }

  // set permission
  Vue.prototype.$readOnly = GET_READONLY(jwt);
  // console.log('$readOnly:', Vue.prototype.$readOnly)
}

// get and set jwt
async function checkJWT(to) {
  // set data for request JWT
  let reqBody = {};
  reqBody.channel = "line";
  reqBody.accessToken = Vue.prototype.$userLine.accessToken;
  const jwt = await getJWT(reqBody);
  if (!jwt) {
    throw { code: "cj1" };
  }

  await saveToken(jwt);
  await checkPermission(jwt, to);
}

// handle like middleware
async function init(to, from, next) {
  try {
    const page = to.query.page;

    // check jwt from url set to variable
    let token = to?.query?.token;
    if (token !== undefined) {
      // console.log('token from url')
      saveToken(token, to);
      checkPermission(token);
    }

    // check aleardy JWT
    if (!Vue.prototype.$JWT && to.meta.auth !== false) {
      // check route have to line authen

      // get data user line
      const userLine = await authLiff(process.env.VUE_APP_LIFF_ROOT);
      // console.log('userLine :', userLine)

      // check user line data has accessToken
      if (!userLine.accessToken) {
        throw { code: "i1" };
      }

      Vue.prototype.$userLine = userLine;

      // init JWT
      await checkJWT(to);

      // set userId for tracking
      userIdTrack =
        Vue.prototype.$userJWT.o?.id || Vue.prototype.$userLine.userId || "";

      // set userId tracking
      setTrack(userIdTrack);
    } else if (to.meta.auth_line !== false) {
      const userLine = await authLiff(process.env.VUE_APP_LIFF_ROOT);
      // check user line data has accessToken
      if (!userLine.accessToken) {
        throw { code: "i1" };
      }

      Vue.prototype.$userLine = userLine;
      // set userId for tracking
      userIdTrack = Vue.prototype.$userLine.userId || "";

      // set userId tracking
      setTrack(userIdTrack);
    }

    // delete page prevent loop check
    delete to.query["page"];

    pushTrack(to.name, to.fullPath);
    return page ? next({ path: page, query: to.query, replace: true }) : next();
  } catch (err) {
    console.error(err);
    await Swal.fire(
      "ข้อผิดพลาดการเข้าใช้",
      `กรุณาเข้าใช้ภายหลังหรือติดต่อเจ้าหน้าที่ ${err.code ? "(" + err.code + ")" : ""
      }`,
      "warning"
    ).then(() => {
      // console.log('closeWindow')
      window.liff.closeWindow();
    });
  }
}

router.beforeEach((to, from, next) => {
  // console.log('from:', from)
  // console.log('to', to)

  init(to, from, next);
  //next()
});

export default router;
