const liff = window.liff
const mock_data = {
    profile: {
        displayName: "devdev",
        pictureUrl: "https://i.imgur.com/xPyGDjE.jpg",
        statusMessage: "it good day",
        userId: "9999999999"
    },
    accessToken: "-"
}

export default async function authLiff(liffId) {
    try {
        // console.log("in liff auth liffId:", liffId)
        if (process.env.NODE_ENV == "development") {
            return mock_data
        }

        await liff.init({ liffId: liffId })

        if (!liff.isLoggedIn()) {
            await liff.login();

            // delay for redirect login
            await new Promise(resolve => setTimeout(resolve, 5000));

            throw 'no redirect line login'
        } else {
            const profile = await liff.getProfile()
            const accessToken = await liff.getAccessToken();
            return { profile: profile, accessToken: accessToken }
        }
    } catch (err) {
        console.error(err)
        return null
    }
}