import jwtDecode from 'jwt-decode'

export const GET_READONLY = (jwt = null) => {
  try {

    if (!jwt) {
      throw "NO JWT"
    }
    const { m } = jwtDecode(jwt)

    switch (m?.mode) {
      case 'rw':
        return false
      case 'r': {
        return true
      }
      default:
        throw "NO PERMISSION"
    }
  } catch (err) {
    console.error(err)
    throw { message: "ผิดพลาดการขอสิทธ์เข้าถึง" }
  }
}