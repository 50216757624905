// show error and close liff window when init error
<template>
  <div id="defualtLayout">
    <slot />
  </div>
</template>
<script>
// import Swal from "sweetalert2";

export default {
  components: {},
  data() {
    return {};
  },
  /* async mounted() {
    // loading fiast enter route with this layout
    window.loading(true);

    // add liff class
    // const liff = new Liff();

    // get user line
    const userLine = await this.$Liff.getLiffProfile();
    console.log(userLine);
    
    // check if user
    if (!userLine) {
      Swal.fire("เกิดข้อผิดพลาด", "กรุณาติดต่อเจ้าหน้าที่", "error").then(
        () => {
          window.liff.closeWindow();
        }
      );
    }

    // fake delay for dev
    setTimeout(() => {
      this.$store.commit("SET_USER_LINE", userLine);
    }, 1000);

    window.loading(false);
  } */
};
</script>
<style  scoped>
#defualtLayout {
  height: 100%;
  max-width: 450px;
  margin: 0 auto;
}
</style>
