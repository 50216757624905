<template>
  <div id="topHead" class="container root">
    <div
      class="row justify-content-between cover header-container"
      @click="goPdpa"
    >
      <div class="col-6 float-left text-left profile align-self-center">
        <!-- <img class="mt-2 p-2" alt="logo" width="80" src="@/assets/logo.png" /> -->
        <div v-if="pointStatus == 'ok'" class="shop-name-section">
          รหัส {{ me.shopCode }}
        </div>
        <div v-if="pointStatus == 'ok'" class="shop-name-section">
          {{ me.shopName }}
        </div>
      </div>
      <div class="col-6 float-right text-right align-self-center">
        <div class="point pr-1 pb-0 mb-0">
          <span v-if="pointStatus == 'loading'">~</span>
          <span v-if="pointStatus == 'negative' || pointStatus == 'error'"
            >-</span
          >
          <span v-if="pointStatus == 'ok'">
            <span>{{ toLocal(point) }}</span>
            <!-- <span>0</span> -->
            <small> P</small>
          </span>
        </div>
        <div class="secondHead" v-if="pointStatus == 'ok'">
          <!-- <span
            v-if="me && pvl.type"
            :class="`txt_type${pvl.saleType !== 'RETAIL' ? ' show' : ''}`"
            data-tag="openShopInfo"
            >{{ pvl.type }}</span
          > -->
          <span
            v-if="me && pvl.type && saleType !== 'wholesale'"
            :class="`txt_type ${pvl.type}`"
            data-tag="openShopInfo"
            >{{ pvl.type }}</span
          >
          <!-- <i class="info icon-info-circled"></i> -->
        </div>
      </div>
    </div>
    <!-- <div
      class="row justify-content-between target-container"
      v-if="saleType === 'wholesale' || saleType === 'exclusivity'"
    >
      <div class="col-6 float-left align-self-center single-line">
        ยอดซื้อสะสม/เป้าสะสม
      </div>
      <div
        class="col-6 float-right text-right align-self-center number-text single-line"
      >
        <span :class="`percentage-text${percentage > 100 ? '' : ' red'}`">
          {{ percentage }}%
        </span>
      </div>
      <div class="col-12 float-left align-self-center number-text single-line">
        <b class="black">{{ $numberWithCommas(min) }}</b> /
        {{ $numberWithCommas(max) }} {{ unit }}
      </div>
    </div> -->
    <b-modal id="pvlTerm_modal" hide-footer centered>
      <template v-slot:modal-header-close>
        <i class="close-modal icon-cancel-circled-outline"></i>
      </template>
      <div class="d-block pvlTerm_body">
        <div :class="'pvl_hero text-center ' + pvl.type">{{ pvl.type }}</div>
        <div class="point_modal">
          <small>รหัสร้านค้า: {{ me.shopCode }}</small>
        </div>
        <!-- Retail -->
        <div v-if="pvl.type == 'Retail'" class="how_retail">
          <b>ขั้นตอนการรับคะแนน</b>
          <div>
            1. กดแทป ‘คะแนน’ เพื่อแสดงรายการของใบเสร็จ
            <br />2. กดปุ่มสีฟ้า ‘กดยืนยันรับคะแนน’ ทางด้านขวาของรายการ ใบเสร็จ
            <br />3. กรอก 4 หลักสุดท้ายของเลขที่ใบเสร็จ ตามวันที่และเลขที่
            ของใบเสร็จที่ปรากฎ <br />4. กดปุ่ม ‘ตกลง’
            เพื่อทำการส่งข้อมูลเข้าระบบ 5. ใบเสร็จที่ทำการกดตกลงแล้ว
            จะปรากฏปุ่มสีเทา มีสถานะเป็น ‘รอการตรวจสอบ’ <br />6. ในวันถัดไป
            ระบบจะแสดงปุ่มสีเขียวและคะแนนของใบเสร็จ
            ที่ผ่านระบบการตรวจสอบเรียบร้อยแล้ว
            <div class="text-center my-2">
              <img src="@/assets/how_retail.png" alt />
            </div>
          </div>
        </div>

        <!-- Wholesale -->
        <div v-if="pvl.type != 'Retail'">
          <div v-if="me.targets" class="target_section font-weight-bold">
            <div class="text-center">เป้าหมายรายเดือน</div>
            <div v-if="pvl.type == 'Exclusivity'" class="text-center">
              {{ me.targets.crystal }} บาท
            </div>
            <div v-else>
              <div v-if="parseInt(me.targets.est)" class="row">
                <div class="col-1">1.</div>
                <div class="col-3 pl-1">est</div>
                <div class="col pl-1">
                  <span class="float-right">
                    {{ me.targets.est }}
                    <span>ลัง</span>
                  </span>
                </div>
              </div>
              <!-- end check est? -->
              <div v-if="parseInt(me.targets.crystal)" class="row">
                <div class="col-1">1.</div>
                <div class="col-3 pl-1">Crystal</div>
                <div class="col pl-1">
                  <span class="float-right">
                    {{ me.targets.crystal }}
                    <span>ลัง</span>
                  </span>
                </div>
              </div>
              <!-- end check crystal? -->
            </div>
            <!-- end check Exclusivity ? -->
          </div>
          <!-- end target? ? -->
        </div>

        <div v-if="pvl.bonus" class="bonus_point_section font-weight-bold">
          <div class="text-center">
            <div>
              คะแนนพิเศษ:
              <span class="bonus_txt">{{ pvl.bonus }}</span>
            </div>

            <div class="des">
              *เมื่อทำตามเป้าหมายรายเดือนที่บริษัทกำหนด
              <br />*การจัดกลุ่มลูกค้ามาจากยอดขายในปี 2019
            </div>
          </div>
        </div>

        <div class="separate"></div>

        <div class="pvlTerm_content" v-html="me.term || ''"></div>
      </div>
      <!-- <div class="text-center">
        <button id="okBtn" class="btn btn-primary mt-3" @click="openEnterPin()">ยอมรับ</button>
      </div>-->
    </b-modal>

    <b-modal id="consent" hide-header hide-footer centered @hide="checkHide">
      <div class="d-block text-center" v-if="pdpa">
        <div
          id="content"
          v-html="
            pdpa && pdpa.content && pdpa.content.replaceAll('101%', '100%')
          "
          @scroll="handleScroll()"
        ></div>
        <div
          class="custom-control custom-checkbox mb-2 text-left d-flex"
          v-for="(item, index) in pdpa.subConsent"
          :key="item.subConsentId"
        >
          <input
            ref="check"
            type="checkbox"
            class="custom-control-input"
            :id="item.subConsentId"
            :name="item.subConsentId"
            v-model="checked_pdpa[index]"
            required
          />
          <label class="custom-control-label" :for="item.subConsentId">
            <span class="sr-only"></span>
          </label>
          <div>
            <div
              v-html="parseLink(item.description)"
              style="display:inline"
            ></div>
          </div>
        </div>
        <div class="text-center">
          <button
            :disabled="!pdpaValid"
            id="okBtn"
            @click="submitPDPA"
            class="btn btn-primary px-5"
          >
            ยืนยัน
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "topHead",
  computed: {
    pdpaValid() {
      const { checked_pdpa, pdpa } = this;
      if (
        pdpa?.subConsent == null ||
        pdpa?.subConsent.every((item, i) => checked_pdpa[i] === true)
      )
        return true;
      return false;
    },
    saleType() {
      const { user_data } = this.$store.state;
      const customerSaleType = user_data?.customerSaleType;
      return customerSaleType ? customerSaleType.toLowerCase() : "";
    },
  },
  data() {
    return {
      point: 0,
      pointStatus: "loading",
      me: {},
      pvl: {
        type: null,
        bonus: null,
      },
      min: 0,
      max: 0,
      percentage: 0,
      unit: "฿",
      startMonth: null,
      endMonth: null,
      consentDetail: {
        title: "ข้าพเจ้าได้อ่านและรับทราบเงื่อนไขและข้อตกลงในการใช้บริการ",
        detail: {
          title: "Title",
          content: "Content",
        },
      },
      checked_pdpa: [],
      pdpa: null,
      allow_hide: false,
      scrollEnd: false,
    };
  },
  watch: {
    // wait user line from layout
    me(val) {
      const { customerSaleType, shopCatName, privilegeTypeCode } = val;
      // this.pvl.type = shopCatName;
      // this.pvl.saleType = customerSaleType;
      if (customerSaleType == "RETAIL") {
        // this.pvl.type = "Retail";
        this.pvl.type = shopCatName;
      } else if (customerSaleType == "EXCLUSIVITY") {
        // this.pvl.type = "Exclusivity";
        this.pvl.type = shopCatName;
      } else {
        switch (privilegeTypeCode) {
          case "1":
            this.pvl.type = "Bronze";
            break;
          case "2":
            this.pvl.type = "Silver";
            this.pvl.bonus = "35,000";
            break;
          case "3":
            this.pvl.type = "Gold";
            this.pvl.bonus = "70,000";
            break;
          case "4":
            this.pvl.type = "Platinum";
            this.pvl.bonus = "100,000";
            break;
          default:
            // this.pvl.type = "Retail";
            this.pvl.type = shopCatName || "Retail";
            break;
        }
      }
    },
  },
  methods: {
    parseLink(html) {
      return html.replaceAll('href=" "', "");
    },
    checkHide(e) {
      if (!this.allow_hide) e.preventDefault();
    },
    handleScroll() {
      const content = document.getElementById("content");

      if (
        content.scrollTop + content.clientHeight >=
        content.scrollHeight - 100
      ) {
        this.scrollEnd = true;
      }
    },
    toLocal(n) {
      return n.toLocaleString();
    },
    async getProfile() {
      return await fetch(process.env.VUE_APP_API_URL + "/me", {
        headers: {
          Authorization: `Bearer ${this.$JWT}`,
        },
      });
    },
    async init() {
      let body = [];
      this.pointStatus = "loading";
      try {
        const res = await this.getProfile();
        // console.log("getProfile status:", res.status);
        switch (res.status) {
          case 200:
            body = await res.json();
            // console.log("getProfile body:", body);
            if (body) {
              if (body.point <= -1) {
                this.pointStatus = "negative";
              } else {
                const { customerSaleType, shopCode } = body;
                this.me = body;
                this.$store.dispatch("setUserData", body);
                this.point = body.point;
                this.pointStatus = "ok";
                if (customerSaleType?.toLowerCase() === "wholesale") {
                  this.getSaleTarget(shopCode);
                }
              }
              this.$store.dispatch("setStoreID", body.shopCode);
            } else {
              throw "getProfile body error";
            }
            return;
          default:
            // console.log("no case:");
            throw "getProfile no case";
        }
      } catch (error) {
        console.log(error);
        window.loading(false);
        this.pointStatus = "error";
      }
    },
    async getSaleTarget(shopCode) {
      try {
        const res = await fetch(
          `${process.env.VUE_APP_API_URL}/shop/${shopCode}/wholesaleTarget`,
          {
            headers: {
              Authorization: `Bearer ${this.$JWT}`,
            },
          }
        );
        const { status } = res;
        switch (status) {
          case 200: {
            const body = await res.json();
            // console.log("target: ", body);
            const { currentTarget, targetReach, percentage } = body;
            this.min = currentTarget || 0;
            this.max = targetReach || 0;
            this.percentage = percentage || 0;
            // const newArray = [];
            const { months } = body;
            // if (months?.length > 0) {
            //   months.forEach((e) => {
            //     if (e.isMock) {
            //       newArray.push({
            //         month: e.month,
            //         year: e.year,
            //         data: [],
            //       });
            //     } else {
            //       newArray.push({
            //         month: e.month,
            //         year: e.year,
            //         data: [
            //           {
            //             title: "เป้าการสั่งซื้อต่อเดือนที่รับคะแนนพิเศษ",
            //             amount: e.targetReach,
            //           },
            //           {
            //             title: "ยอดการสั่งซื้อ",
            //             amount: e.currentTarget,
            //           },
            //           {
            //             title: "% เทียบเป้า",
            //             amount: e.percentage,
            //           },
            //           // {
            //           //   title: "เป้าหมาย",
            //           //   list: [
            //           //     {
            //           //       title:
            //           //         "เป้าหมายเดือน " + this.$getDate(e.month - 1),
            //           //       amount: e.targetReach,
            //           //     },
            //           //     {
            //           //       title: "เป้าหมายสะสม",
            //           //       amount: e.accumulateTargetReach,
            //           //     },
            //           //   ],
            //           // },
            //           // {
            //           //   title: "ยอดซื้อ",
            //           //   list: [
            //           //     {
            //           //       title: "ยอดซื้อเดือน " + this.$getDate(e.month - 1),
            //           //       amount: e.currentTarget,
            //           //     },
            //           //     {
            //           //       title: "ยอดซื้อสะสม",
            //           //       amount: e.accumulateCurrentTarget,
            //           //     },
            //           //   ],
            //           // },
            //         ],
            //       });
            //     }
            //   });
            //   newArray.sort((a, b) => {
            //     if (a.year === b.year) {
            //       return a.month - b.month;
            //     } else {
            //       return a.year - b.year;
            //     }
            //   });
            // }
            this.$store.dispatch("setWholesaleTarget", months);
            break;
          }
          default:
            throw "Something when wrong!";
        }
      } catch (e) {
        console.log(e);
      }
    },
    goPdpa() {
      this.$router.push("/pdpa");
    },

    async fetchPDPA() {
      window.loading(true);
      try {
        const res = await fetch(
          process.env.VUE_APP_API_URL + "/user/consents/pdpa/waiting",
          {
            headers: {
              Authorization: `Bearer ${this.$JWT}`,
            },
          }
        );
        let data = [];
        switch (res.status) {
          case 200:
            data = await res.json();
            this.pdpa = data[0];
            if (this.pdpa != null) {
              this.allow_hide = false;
              this.$bvModal.show("consent");
            }
            break;
          default:
            throw ("no case", res);
        }
      } catch (error) {
        console.error("fetchPDPA error:", error);
        this.$Error();
      } finally {
        window.loading(false);
      }
    },

    async submitPDPA() {
      try {
        this.allow_hide = true;
        this.$bvModal.hide("consent");
        window.loading(true);
        const jsonData = {
          data: [
            {
              consentId: this.pdpa.id,
              isAccept: true,
              subConsentAccept: this.pdpa.subConsent.map((sc) => ({
                subConsentId: sc.subConsentId,
                isAccept: true,
              })),
            },
          ],
        };
        const res = await fetch(process.env.VUE_APP_API_URL + "/consents", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.$JWT}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(jsonData),
        });
        switch (res.status) {
          case 200:
            window.loading(false);
            break;
          default:
            throw ("no case", res);
        }
      } catch (error) {
        this.allow_hide = false;
        this.$bvModal.show("consent");
        console.error("fetchWaitingPDPA error:", error);
        this.$Error();
      } finally {
        window.loading(false);
      }
    },
  },
  async mounted() {
    await this.init();
    await this.fetchPDPA();
  },
};
</script>

<style lang="scss" scoped>
$black: #000;
$blue: #189bd6;
$blue-font: #189ad6;
$green: #b2d235;
$light-blue: #00c0ff;
$white: #fff;
$yellow: #f8e71c;

.header-container {
  cursor: pointer;
}

.cover {
  background: $blue;
  min-height: 80px;
  /* background-image: url("~@/assets/cover_main.svg");
  background-size: cover;
  background-repeat: no-repeat; */
}

.target-container {
  background: $light-blue;
  color: $white;
  font-weight: 500;
  min-height: 45px;
  .single-line {
    white-space: nowrap;
  }
  .black {
    color: $black !important;
    font-weight: 700;
  }
  .percentage-text {
    color: $yellow;
    &.red {
      color: red;
    }
  }
}

.point,
.profile,
.info {
  color: $white;
}

.profile {
  align-self: center;
  .shop-name-section {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.point {
  // margin-top: 15px;
  line-height: 30px;
  // font-size: 28px;
  text-shadow: 2px 1px 1px rgba(0, 0, 0, 0.2);
}

.txt_type {
  // background: $green;
  // color: $white;
  font-size: 14px;
  border-radius: 16px;
  padding: 0px 10px;
  line-height: 0;
  font-weight: 500;
  white-space: nowrap;
  // &.WHOLESALES,
  // &.EXCLUSIVITY {
  //   background: $white;
  //   color: $blue-font;
  // }
  // &.show {
  //   background: $white;
  //   color: $blue-font;
  // }
}

.Exclusivity {
  color: #ffffff;
  background: #000000;
}

.Platinum {
  color: #ffffff;
  background: #b6afa9;
}

.Gold {
  color: #ffffff;
  background: #fdd017;
}

.Silver {
  color: #ffffff;
  background: #c0c0c0;
}

.Bronze {
  color: #ffffff;
  background: #b87333;
}

.Retail {
  color: #189ad6;
  background: #ffffff;
}

.Exclusivity {
  color: #ffffff;
  background: #000000;
}

.info {
  padding-left: 0;
  margin-left: 0;
}

.pvlTerm_content {
  line-height: 24px;
}

.pvl_hero {
  height: 50px;
  font-size: 28px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  /* background: #b6afa9; */
  border-radius: 8px;
  margin: 22px 0 10px 0;
}

.separate {
  margin: 10px auto;
  border: 2px solid #dddddd;
  width: 95%;
}

.pvlTerm_body {
  overflow: scroll;
  max-height: 90vh;
}

.target_section {
  padding: 10px 15px;
  font-size: 18px;
  line-height: 35px;
  border-radius: 8px;
  background: #189ad6;
  color: #ffffff;
}

.bonus_point_section {
  padding: 10px 15px;
  font-size: 20px;
  line-height: 35px;
  border-radius: 8px;
  background: #b2d235;
  color: #000000;
  margin: 5px auto;
}
.bonus_txt {
  background: #ffffff;
  border: 2px solid #000000;
  border-radius: 30px;
  padding: 0 10px;
  margin: 5px auto;
}

.bonus_point_section .des {
  line-height: 25px;
  margin-top: 10px;
  font-size: 16px;
}

.point_modal {
  text-align: center;
  font-size: 20px;
  background: #189ad6;
  color: #ffffff;
  padding: 5px;
  border-radius: 8px;
  line-height: 28px;
  margin: 5px auto;
}
</style>
