// show error and close liff window when init error
<template>
  <div id="coupon-layout">
    <div id="fixTop">
      <TopHead />
      <TabMain />
    </div>
    <div
      id="coupon-layout-content"
      class="coupon-layout-content"
      :style="{ paddingTop: fixTop + 'px' }"
    >
      <NavTab v-if="showNav" />
      <slot />
    </div>
  </div>
</template>
<script>
import TopHead from "@/components/topHead.vue";
import TabMain from "@/components/tabMain.vue";
import NavTab from "@/components/navTab.vue";

export default {
  components: { TopHead, TabMain, NavTab },
  computed: {
    fixTop() {
      const { customerSaleType } = this.$store?.state?.user_data;
      let fixHeight = 125;
      switch (customerSaleType?.toLowerCase()) {
        case "exclusivity":
        case "wholesale": {
          fixHeight = 132;
          break;
        }
        default:
          break;
      }
      return fixHeight;
    },
  },
  data: () => ({
    showNav: false,
  }),
  mounted() {
    const { name } = this.$route;
    switch (name) {
      case "all-coupon": {
        break;
      }
      case "my-coupon":
      case "use-coupon": {
        this.showNav = true;
        break;
      }
      default: {
        break;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
#coupon-layout {
  max-width: 450px;
  margin: 0 auto;
}
#fixTop {
  z-index: 2;
  position: fixed;
  width: 100%;
  max-width: inherit;
}
.coupon-layout-content {
  /* padding-top: 135px; */
  overflow: auto;
}
</style>
