import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VConsole from 'vconsole'
import Swal from 'sweetalert2'
import VueLazyload from 'vue-lazyload'
import VueThailandAddress from 'vue-thailand-address'

import App from './App.vue'
import router from './router'
import store from './store'
import './style/bootstrap/custom.scss'

import DefaultLayout from './layouts/Default.vue'
import MainLayout from './layouts/Main.vue'
import CampaignLayout from './layouts/CampaignLayout.vue'
import CouponLayout from './layouts/CouponLayout.vue'
import RewardLayout from './layouts/RewardLayout.vue'
import SermsukClickLayout from './layouts/SermsukClickLayout.vue'

window.$pushEvent = (c, a, n) => {
  try {
    window['_paq'].push(['trackEvent', c, a, n])
  } catch (error) {
    console.log(error)
  }
}

Vue.use(VueThailandAddress)

// layout

Vue.component('defaultLayout', DefaultLayout)
Vue.component('mainLayout', MainLayout)
Vue.component('campaignLayout', CampaignLayout)
Vue.component('couponLayout', CouponLayout)
Vue.component('rewardLayout', RewardLayout)
Vue.component('ssclickLayout', SermsukClickLayout)

if (process.env.VUE_APP_MODE == 'development') {
  new VConsole()
}

if (process.env.VUE_APP_MODE == 'dev') {
  new VConsole()
}

if (process.env.VUE_APP_MODE == 'uat') {
  new VConsole()
}

// console.log(process.env)

//Vue.prototype.$Liff = new Liff()

Vue.config.productionTip = false

// VueLazyload
Vue.use(VueLazyload, {
  lazyComponent: true,
  preLoad: 1,
  error: require('@/assets/error_image.svg'),
  loading: require('@/assets/loading_image.svg'),
  attempt: 1,
})

// set sweetAlert2 in prototype
Vue.prototype.Swal = Swal

// set alert error general in prototype
Vue.prototype.$Error = (val) => {
  window.loading(false)
  Swal.fire(
    'ขออภัย',
    'พบข้อขัดข้อง กรุณาทำรายการใหม่ภายหลัง หรือติดต่อเจ้าหน้าที่',
    'warning'
  ).then(() => {
    if (val) {
      window.liff.closeWindow()
    }
  })
}

// set alert error 401 in prototype
Vue.prototype.$Error401 = () => {
  window.loading(false)
  Swal.fire(
    'ขออภัย',
    'พบสิทธ์ขัดข้อง กรุณาทำรายการใหม่ภายหลัง หรือติดต่อเจ้าหน้าที่',
    'warning'
  ).then(() => {
    window.liff.closeWindow()
  })
}

Vue.prototype.$LogFormData = (name, fd) => {
  // Display the key/value pairs
  // console.log(name)
  // for (const pair of fd.entries()) {
  //   console.log(pair[0] + ", " + pair[1]);
  // }
  // for (const x of [...fd]) {
  //   console.log(x[0] + ":" + [1]);
  // }

  let es, e, pair
  for (es = fd.entries(); !(e = es.next()).done && (pair = e.value); ) {
    console.log(pair[0] + ': ' + pair[1])
  }
  console.log('----')
}

window.callEvent = (elm, ev) => {
  let event
  if (typeof Event === 'function') {
    event = new Event(ev)
  } else {
    event = document.createEvent('Event')
    event.initEvent(ev, true, true)
  }
  elm.dispatchEvent(event)
}

Vue.prototype.$toLocal = (n) => {
  return n.toLocaleString()
}

Vue.prototype.$numberWithCommas = (number) => {
  return new Intl.NumberFormat().format(number)
}

Vue.prototype.$getDate = (n) => {
  const monthNamesThai = [
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค.',
  ]
  return monthNamesThai[n]
}

Vue.prototype.$strToDate = (n) => {
  const monthNamesThai = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤษจิกายน',
    'ธันวาคม',
  ]

  const date = new Date(n)

  return monthNamesThai[date.getMonth()] + ' ' + (date.getFullYear() + 543)
}

Vue.prototype.$getFullDate = (n) => {
  const monthNamesThai = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤษจิกายน',
    'ธันวาคม',
  ]

  const date = new Date(n)

  return (
    date.getDate() +
    ' ' +
    monthNamesThai[date.getMonth()] +
    ' ' +
    (date.getFullYear() + 543)
  )
}

Vue.prototype.$convertToThaiDatetime = (isoDate) => {
  // Create a Date object from the ISO date string
  const date = new Date(isoDate)

  // Get the Thai year
  const thaiYear = date.getFullYear() + 543

  // Get the Thai month using Thai names
  const thaiMonths = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ]
  const thaiMonth = thaiMonths[date.getMonth()]

  // Get the day of the month
  const thaiDay = date.getDate()

  // Format the time
  const thaiTime = date.toLocaleTimeString('th-TH', { hour12: false })

  // Combine the components into the Thai datetime format
  const thaiDatetime = `${thaiDay} ${thaiMonth} ${thaiYear} ${thaiTime}`

  return thaiDatetime
}

Vue.prototype.$invoToString = (val) => {
  const splitLength = 4
  const startSubStringLength = val.length % splitLength

  let regString = ''
  if (startSubStringLength == 0) {
    regString = `(.{1,${splitLength}})|(.{1,})`
  } else {
    regString = `(^.{1,${startSubStringLength}})|(.{1,${splitLength}})|(.{1,})`
  }

  return val.match(new RegExp(regString, 'g')).join('-')
}

Vue.prototype.$JWT = ''
Vue.prototype.$userLine = {}

Vue.use(BootstrapVue)

// check line version and alert
if (process.env.VUE_APP_MIN_LINE_VERSION) {
  const userAgent = window.navigator.userAgent
  const targetStr = userAgent.indexOf('Line/')
  if (targetStr != -1) {
    const lineAgent = userAgent
      .substr(targetStr, userAgent.indexOf(' '))
      .substr(targetStr.length)
    const line_version = lineAgent.split('/')[1]
    if (
      parseFloat(line_version) <
      parseFloat(process.env.VUE_APP_MIN_LINE_VERSION)
    ) {
      setTimeout(() => {
        Swal.fire({
          html: `Line ของคุณเวอร์ชั่นเก่า<br>กรุณาอัปเดตแอปพลิเคชั่น Line เพื่อการใช้งานที่สมบูรณ์<br>(เวอร์ชั่นของคุณ Line ${line_version})`,
          icon: 'warning',
        })
      }, 100)
    }
  }
}

Vue.mixin({
  computed: {
    $userData() {
      return store.state.user_data
    },
  },
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app_root')
